// src/admin/notFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>Trang bạn đang tìm không tồn tại.</p>
    </div>
  );
};

export default NotFound;
