import React from 'react';
import '../css/foot.css'; 

const Footer = () => {
    return (
        <div className="about-foot">
            <p>
                <a href="https://www.facebook.com/Interior.luxus" target="_blank" rel="noopener noreferrer">Facebook</a>
              
            </p>
            <p>&copy; LUXUS 2024</p>
        </div>
    );
}

export default Footer;
